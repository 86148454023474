<template>
  <div>
    <b-modal
      id="modal-1"
      :title="msgModaleTitle"
      ref="msg-modal"
      ok-only
      :no-close-on-backdrop="true"
    >
      <p class="my-4">{{ msg }}</p>
    </b-modal>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :search="search"
      class="elevation-1"
      style="font-family: Poppins, Helvetica: sans-serif"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Questions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            @click="editItem()"
            >New Question</v-btn
          >
          <v-dialog v-model="dialog" max-width="75%">
            <v-card>
              <v-card-title>
                <span class="headline">Question Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      CC Question Number
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.cc_ques_no }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Question Status
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.ques_status }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      CC Integrated Reference
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.cc_mapping }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Questionnaire
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.questionnaire }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Topic
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.topic }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Question
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.ques_text }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Excerpt
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.excerpt }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Evidence Expires in
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.expiry_date }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Critical Question</v-col
                    >
                    <v-col cols="12" sm="9">
                      {{ editedItem.critical_ques }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Regulations
                    </v-col>
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="regulation in editedItem.regulations"
                          v-bind:key="regulation"
                        >
                          {{ regulation }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Platforms
                    </v-col>
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="platform in editedItem.platforms"
                          v-bind:key="platform"
                        >
                          {{ platform }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Environments
                    </v-col>
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="environment in editedItem.environments"
                          v-bind:key="environment"
                        >
                          {{ environment }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Tags
                    </v-col>
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li v-for="tag in editedItem.tags" v-bind:key="tag">
                          {{ tag }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Question Attachment Text
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{ editedItem.help_text }}
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">
                      Control Numbers
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p
                        v-for="ctrl in editedItem.controls"
                        v-bind:key="ctrl.regln_name"
                      >
                        <span class="font-weight-bold"
                          >{{ ctrl.regln_name }} :</span
                        >
                        &nbsp; {{ ctrl.ctrl_no }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-b-tooltip.hover.bottom="'View Question Details'"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          info
        </v-icon>
        <v-icon
          v-b-tooltip.hover.bottom="'Edit Question Details'"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-b-tooltip.hover.bottom="'Delete Question'"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import CCApiService from "@/core/services/aws.ccapi.service";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [],
      tableData: [],
      loading: false,
      editedItem: {
        ques_id: 0,
        cc_ques_no: "",
        cc_mapping: "",
        topic: "",
        ques_text: "",
        excerpt: "",
        expiry_date: "",
        critical_ques: 0,
        help_text: "",
        tag: "",
        regulation: "",
        platform: "",
        environment: "",
        controls: "",
        questionnaire: "",
        ques_status: ""
      }
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    localStorage.setItem("ques_id", 0);
    this.initialize();
  },

  mounted() {},

  methods: {
    initialize() {
      this.headers = [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "ques_id",
          width: "5%"
        },
        { text: "CC Question Number", value: "cc_ques_no", width: "10%" },
        { text: "CC Integrated Reference", value: "cc_mapping", width: "10%" },
        { text: "Topic", value: "topic", width: "10%" },
        { text: "Question", value: "ques_text", width: "45%" },
        { text: "Status", value: "ques_status", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" }
      ];
      this.getQuestions();
    },

    editItem(item) {
      var ques_id =
        item != undefined && item.hasOwnProperty("ques_id") && item.ques_id > 0
          ? item.ques_id
          : 0;

      localStorage.setItem("ques_id", ques_id);
      this.$router.push({ path: "generate-question/" + ques_id });
    },

    viewItem(item) {
      if (item == undefined || item.ques_id == undefined) {
        return;
      }
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {});
    },

    deleteItem: async function(item) {
      const id =
        item != undefined && item.hasOwnProperty("ques_id") && item.ques_id > 0
          ? item.ques_id
          : 0;
      if (id > 0 && confirm("Are you sure you want to delete this item?")) {
        // 1. Delete database
        const apiName = "OneAudit";
        const path = "/questions/" + id;
        CCApiService.delete(apiName, path)
          .then(() => {
            this.initialize();
            this.showMsg("success", "Record successfully deleted");
          })
          .catch(error => {
            this.showMsg("error", error);
          });
      }
    },
    getQuestions() {
      const apiName = "OneAudit";
      const path = "/questions";
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tableData = [];
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var environmentNames = [];
                  var regulationNames = [];
                  var platformNames = [];
                  var tagNames = [];
                  var controlNames = [];

                  if (response[index].environments != undefined) {
                    for (var i in response[index].environments) {
                      environmentNames.push(
                        response[index].environments[i].env_name
                      );
                    }
                  }

                  if (response[index].regulations != undefined) {
                    for (var j in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[j].regln_name
                      );

                      for (var x in response[index].controls) {
                        if (x == response[index].regulations[j].regln_id) {
                          controlNames.push({
                            regln_name:
                              response[index].regulations[j].regln_name,
                            ctrl_no: response[index].controls[x].toString()
                          });
                        }
                      }
                    }
                  }

                  if (response[index].platforms != undefined) {
                    for (var k in response[index].platforms) {
                      platformNames.push(
                        response[index].platforms[k].plat_name
                      );
                    }
                  }

                  if (response[index].tags != undefined) {
                    for (var t in response[index].tags) {
                      tagNames.push(response[index].tags[t].tag_name);
                    }
                  }

                  if (response[index].ques_id == undefined) {
                    break;
                  }

                  this.tableData.push({
                    ques_id: response[index].ques_id,
                    cc_mapping: response[index].cc_mapping,
                    cc_ques_no: response[index].cc_ques_no,
                    ques_text: response[index].ques_text,
                    excerpt: response[index].excerpt,
                    expiry_date: response[index].expiry_date + " days",
                    critical_ques:
                      response[index].critical_ques == 1 ? "Yes" : "No",
                    help_text: response[index].help_text,
                    regulations: regulationNames,
                    platforms: platformNames,
                    environments: environmentNames,
                    tags: tagNames,
                    controls: controlNames,
                    questionnaire: response[index].questionnaire.name,
                    topic: response[index].topic.name,
                    ques_status:
                      response[index].ques_step_status == "published"
                        ? "Completed"
                        : "Draft"
                  });
                }
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRegulationNameById: function(id) {
      for (var i = 0; i < this.editedItem.length; i++) {
        if (this.regulations[i].value == id) {
          return this.regulations[i].text;
        }
      }
    },
    showMsg: function(type, msg) {
      this.msgModaleTitle = type == "error" ? "Error" : "Message";
      this.msg = msg;
      this.$refs["msg-modal"].show();
    }
  }
};
</script>
